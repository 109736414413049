import { VStack, Text, Button, Link, Image, Wrap } from '@chakra-ui/react'
import { Infobox } from '@opengovsg/design-system-react'
import { FeatureFlag } from '@prisma/client'
import { useMemo } from 'react'
import { BiLogInCircle } from 'react-icons/bi'
import { FooterWithCare360 } from '~/components/Footer/Footer'
import { INSTITUTIONS } from '~/constants/institutions'
import { useLogin } from '~/hooks/auth'
import { type NextPageWithLayout } from '~/lib/types'
import { DefaultLayout } from '~/templates/layouts/DefaultLayout'
import { hasFeatureFlag } from '~/utils/featureFlags'
import { trpc } from '~/utils/trpc'

const Index: NextPageWithLayout = () => {
  const { mutate: login } = useLogin()

  const [config] = trpc.appConfig.get.useSuspenseQuery()
  const loginEnabled = useMemo(
    () => hasFeatureFlag(FeatureFlag.LOGIN, config?.featureFlags),
    [config?.featureFlags]
  )

  return (
    <VStack align="flex-start" w="full" spacing={10}>
      <VStack
        px={6}
        py={16}
        spacing={6}
        w="full"
        bgImage={`url('/assets/hero-bg.png')`}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        textAlign="center"
      >
        <Text textStyle="h3" fontWeight="semibold">
          Request for Financial Assistance
        </Text>

        <Text textStyle="body-1">
          You can use this service if you have ever made an appointment with a
          Medical Social Worker at a partner hospital.
        </Text>

        <VStack w="full" spacing={4}>
          <Text textStyle="caption-2">Partner hospitals:</Text>
          <Wrap w="full" justify="center" spacing={4}>
            {Object.keys(INSTITUTIONS).map((inst) => (
              <Image
                key={inst}
                src={INSTITUTIONS[inst]?.logo ?? ''}
                alt={INSTITUTIONS[inst]?.fullName ?? ''}
                h="40px"
              />
            ))}
          </Wrap>
        </VStack>

        <Infobox variant="info" textAlign="left">
          <Text textStyle="body-2">
            Note: Prepare{' '}
            <strong>
              latest bank statements, payslips/CPF/IRAS statements, and any
              other supporting financial documents
            </strong>{' '}
            for the patient and their family to expedite the application
          </Text>
        </Infobox>

        <Button
          rightIcon={<BiLogInCircle />}
          colorScheme="blue"
          onClick={() => {
            login()
          }}
          isDisabled={!loginEnabled}
          mt={0}
        >
          Log in with Singpass app
        </Button>

        <Text textStyle="caption-2">
          Don&apos;t have Singpass app?{' '}
          <Link
            textDecoration="none"
            href="https://app.singpass.gov.sg/"
            isExternal
          >
            Download now
          </Link>
        </Text>
      </VStack>

      <FooterWithCare360 />
    </VStack>
  )
}

Index.getLayout = DefaultLayout

export default Index
